import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Button,
} from '@la/ds-ui-components';
import { Division } from '@la/types';
import { getProgramTeams } from 'lib/apis/getProgramTeams';
import { Team } from 'redux/services/types/team';
import * as S from './DivisionDisplay.styles';

export interface DivisionDisplayProps {
  addRef: (id: string, ref: MutableRefObject<HTMLElement | null>) => void;
  division: Division;
}

export function DivisionDisplay({ addRef, division }: DivisionDisplayProps) {
  const [hiddenTeams, setHiddenTeams] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);

  const divisionDisplayHeaderRef = useRef(null);

  useEffect(() => {
    getProgramTeams({ programId: division.id })
      .then((res) => {
        setTeams(res);
      })
      .catch((err) => {
        console.error(`Error retrieving teams for division ${division.id}`);
        console.error(err);
      })
      .finally(() => {
        console.info(`Finishing request for teams of division ${division.id}`);
      });
  }, [division.id]);

  const toggleDisplay = useCallback(
    () => setHiddenTeams(!hiddenTeams),
    [hiddenTeams]
  );

  const TableDisplay = useCallback(
    () => (
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Team name</TableHeader>
            <TableHeader>Organization</TableHeader>
            <TableHeader>Location</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {teams.map((team) => (
            <TableRow key={`participants${division.id}-${team.id}`}>
              <TableCell>{team.name}</TableCell>
              <TableCell>{team.organization}</TableCell>
              <TableCell>
                {team.city}, {team.admin1}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    ),
    [division.id, teams]
  );

  const EmptyTeamListDisplay = useCallback(
    () => (
      <S.EmptyTeamListContainer>
        No participants yet. Be the first and register now!
      </S.EmptyTeamListContainer>
    ),
    []
  );

  useEffect(() => {
    if (divisionDisplayHeaderRef.current) {
      addRef(division.id, divisionDisplayHeaderRef);
    }
  }, [addRef, division.id, divisionDisplayHeaderRef]);

  return (
    <S.PageContainer>
      <S.Header ref={divisionDisplayHeaderRef}>
        <S.TeamNameHeader>
          <S.TeamNameDisplay>{division.name}</S.TeamNameDisplay>
          <S.AgeGroupDisplay>{teams?.length || 0}</S.AgeGroupDisplay>
        </S.TeamNameHeader>
        <section>
          <Button
            onClick={toggleDisplay}
            size="small"
            variant="text"
            hasPadding={false}
          >
            {hiddenTeams ? 'Show teams' : 'Hide Teams'}
            {hiddenTeams ? <S.AccordionCaretDown /> : <S.AccordionCaretUp />}
          </Button>
        </section>
      </S.Header>
      {!hiddenTeams && teams.length > 0 ? (
        <TableDisplay />
      ) : teams.length > 0 ? (
        <S.CollapsedListRule />
      ) : (
        <EmptyTeamListDisplay />
      )}
    </S.PageContainer>
  );
}
