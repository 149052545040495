import styles from '../Checkout.module.scss';
import { CartItemData } from '../Checkout.types';
import OrderSummaryCard from '../OrderSummaryCard/OrderSummaryCard';

export type OrderSummaryCardProps = {
  cartItems: CartItemData[];
};

/* cartItemsCard*/
export default function OrderSummaryCards({
  cartItems,
}: Readonly<OrderSummaryCardProps>) {
  return cartItems ? (
    <>
      <p className={styles.sectionHead}>Review order summary</p>
      {cartItems.map((cartItemData) => (
        <OrderSummaryCard
          cartItemData={cartItemData}
          key={cartItemData.cartItemUuid}
          isShowingAdditionalButtons={true}
        />
      ))}
    </>
  ) : null;
}
/* */
