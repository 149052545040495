import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  Checkbox,
  Message,
  Typography,
  WarningIcon,
} from '@la/ds-ui-components';
import { Alert } from '@la/shared-components/src/components/Alert/Alert';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { addPlaidBankAccount } from 'lib/plaid/plaid';
import { getSiteId, getSiteName } from 'redux/coreSlice';
import { useGetPaymentMethodQuery } from 'redux/services/checkoutApi';
import { useGetUserIdQuery } from 'redux/services/userInfo';
import { useAppSelector } from 'redux/store';
import checkoutStyles from '../Checkout.module.scss';
import {
  PaymentMethod,
  PaymentMethodInfo,
  SingleUseCard,
  StoredPaymentMethod,
} from '../Checkout.types';
import { CHECKOUT_ERRORS } from '../CheckoutErrors';
import { PaymentNetwork } from '../PaymentMethodBadge/PaymentMethodBadgeConfig';
import { PaymentMethodBadgeGroup } from '../PaymentMethodBadge/PaymentMethodBadgeGroup';
import UseAccountModal from '../UseAccountModal/UseAccountModal';
import UseCardModal from '../UseCardModal/UseCardModal';
import styles from './PaymentMethodCard.module.scss';
import * as S from './PaymentMethodCard.styles';

export type ModalName = '' | 'useAccount' | 'useCard' | 'editPaymentMethod';

export type PaymentModalsProps = {
  closeModal: () => void;
  hasBackButton: boolean;
  modalName: ModalName;
  openEditPaymentModal: () => void;
};

export function isSingleUseCard(
  paymentMethod: PaymentMethod | null
): paymentMethod is SingleUseCard {
  return (
    !!paymentMethod &&
    (paymentMethod as SingleUseCard).cardType === 'SINGLE_USE'
  );
}

/* PaymentMethodCard*/
export default function PaymentMethodCard() {
  const [searchParams] = useSearchParams();
  const isAccountConnected = searchParams.get('accountConnected') === 'true';
  const {
    cartHasAutopayPaymentOption,
    checkoutErrorsAreVisible,
    checkoutFieldErrorStates,
    selectedPaymentMethod,
    hasAutopayPaymentOptionSelected,
    hasAgreedToAutopay,
    updateHasAgreedToAutopay,
    updateSelectedPaymentMethod,
  } = useCheckoutInfo();
  const siteId = useAppSelector(getSiteId);
  const siteName = useAppSelector(getSiteName);
  const { data: userId } = useGetUserIdQuery(siteId);
  const skip = !userId || !siteId;
  const { data: storedPaymentMethodsData /*isLoading,error */ } =
    useGetPaymentMethodQuery(
      {
        siteId,
        userId,
      },
      { skip }
    );
  const hasPaymentMethod = !!selectedPaymentMethod;
  const [openModalName, setOpenModalName] = useState<ModalName>('');

  const { cartCheckoutPaymentTerms } = useFlags();

  function openEditPaymentModal() {
    setOpenModalName('editPaymentMethod');
  }

  function openCardModal() {
    setOpenModalName('useCard');
  }

  function closeModal() {
    setOpenModalName('');
  }

  /**
   * This useEffect is used to determine what the current selected payment
   * method should be. If a temporary credit card has been added it will be
   * that, otherwise it should be selected from the stored payment methods.
   *
   * - if there is already a value for selectedPaymentMethod in
   * CheckoutInfoContext or `storedPaymentMethodsData` is `null`
   * -- exit the useEffect with `return`
   * - storedPaymentMethodsData is retrieved via useGetPaymentMethodQuery
   * - the stored payment methods are aggregated into a single array
   * - the first stored payment method that has `isPrimaryPaymentOption` set to
   * true is set as the value of `primaryPaymentMethod` (yes, more than one
   * can be set in the DB with `isPrimaryPaymentOption: true`)
   * - if none of the stored payment methods has `isPrimaryPaymentOption: true`
   * -- then the first stored payment method in the array is set as the
   * value of `primaryPaymentOption`
   * - if the isAccountConnected query param is true, then use the first stored bank account, if one exists
   * - if there are no stored payment methods
   * -- then primaryPaymentMethod will be `null`
   * - update selectedPaymentMethod in CheckoutInfoContext with
   * `primaryPaymentMethod`
   */
  useEffect(() => {
    if (selectedPaymentMethod || !storedPaymentMethodsData) {
      return;
    }

    const storedBankAccounts =
      storedPaymentMethodsData?.storedBankAccounts ?? [];
    const storedCreditCards = storedPaymentMethodsData?.storedCreditCards ?? [];
    const paymentMethods: StoredPaymentMethod[] = [
      ...storedCreditCards,
      ...storedBankAccounts,
    ];
    let primaryPaymentMethod =
      paymentMethods.find(
        (paymentMethod) => paymentMethod.isPrimaryPaymentOption
      ) ??
      paymentMethods[0] ??
      null;
    if (isAccountConnected && storedBankAccounts[0]) {
      primaryPaymentMethod = storedBankAccounts[0];
    }

    updateSelectedPaymentMethod(primaryPaymentMethod);
  }, [
    isAccountConnected,
    storedPaymentMethodsData,
    selectedPaymentMethod,
    updateSelectedPaymentMethod,
  ]);

  const isShowingErrorMessage =
    checkoutFieldErrorStates.hasPaymentMethodError && checkoutErrorsAreVisible;

  let paymentMethodError = CHECKOUT_ERRORS.paymentMethod;
  if (cartCheckoutPaymentTerms) {
    if (selectedPaymentMethod && hasAutopayPaymentOptionSelected) {
      const { paymentMethodId } = selectedPaymentMethod;
      if (paymentMethodId && hasAgreedToAutopay) {
        paymentMethodError = CHECKOUT_ERRORS.storePaymentMethod;
      } else if (paymentMethodId && !hasAgreedToAutopay) {
        paymentMethodError = CHECKOUT_ERRORS.autopay;
      } else {
        paymentMethodError = CHECKOUT_ERRORS.storePaymentMethodAndAutopay;
      }
    } else if (hasAutopayPaymentOptionSelected) {
      paymentMethodError = CHECKOUT_ERRORS.paymentMethodWithAutopay;
    }
  }

  const FAKE_TEMPORARY_CREDIT_CARD: PaymentMethodInfo = {
    storedCreditCards: [
      {
        createdOn: '2025-02-07T16:33:13',
        last4Digits: '2323',
        paymentNetwork: PaymentNetwork.Visa,
        storedCreditCardId: 8313979,
        isPrimaryPaymentOption: false,
        expirationDate: '2029-05-01T00:00:00',
        cardType: 'STORED',
        paymentType: 'CARD',
        paymentMethodId: 8313978,
      },
    ],
    paymentOptionsCount: 1,
  };

  return (
    <>
      <p className={checkoutStyles.sectionHead}>Select payment method</p>
      <div className={styles.paymentMethodCard}>
        <p className={styles.informationalCopy}>
          Bank accounts are stored automatically.
        </p>
        {isSingleUseCard(selectedPaymentMethod) &&
        hasAutopayPaymentOptionSelected ? (
          <S.AutopayPaymentMethodWarning>
            <Alert
              icon={<WarningIcon variant="bold" />}
              message="You have opted to use a payment option that requires a stored payment method. Please edit your current payment method."
            />
          </S.AutopayPaymentMethodWarning>
        ) : null}
        <PaymentMethodBadgeGroup
          groupId="stored-payment-methods"
          paymentTypes="stored"
          paymentMethodData={storedPaymentMethodsData}
        />
        <PaymentMethodBadgeGroup
          groupId="additional-payment-methods"
          paymentTypes="additional"
          paymentMethodData={FAKE_TEMPORARY_CREDIT_CARD}
        />
        <div className={styles.actionButtons}>
          <Button
            rightIcon={<ArrowSquareOut weight="bold" />}
            size="medium"
            onClick={() => addPlaidBankAccount(userId, siteId)}
          >
            {storedPaymentMethodsData?.storedBankAccounts
              ? 'Replace bank account'
              : 'Add bank account'}
          </Button>
          <Button size="medium" onClick={openCardModal}>
            {storedPaymentMethodsData?.storedCreditCards
              ? 'Use another Card'
              : 'Add a Card'}
          </Button>
        </div>
        {cartCheckoutPaymentTerms && cartHasAutopayPaymentOption ? (
          <S.AutopayContainer>
            <Checkbox
              ariaLabel="agree to autopay"
              disabled={
                !hasPaymentMethod || isSingleUseCard(selectedPaymentMethod)
              }
              id="autopay-agreement"
              label="Agree to autopay"
              size="large"
              onCheckedChange={updateHasAgreedToAutopay}
              required={hasAutopayPaymentOptionSelected}
            />
            <S.AutopayDisclaimer>
              <Typography size="medium" variant="ui">
                By agreeing to autopay, you authorize {siteName} to charge your
                card for all registrations using payment options in your cart.
              </Typography>
            </S.AutopayDisclaimer>
          </S.AutopayContainer>
        ) : null}
      </div>

      {isShowingErrorMessage ? (
        <Message messageType="error">{paymentMethodError}</Message>
      ) : null}
      <PaymentModals
        closeModal={closeModal}
        hasBackButton={hasPaymentMethod}
        modalName={openModalName}
        openEditPaymentModal={openEditPaymentModal}
      />
    </>
  );
}
/* */

/* PaymentModals */
function PaymentModals({
  closeModal,
  hasBackButton,
  modalName,
  openEditPaymentModal,
}: PaymentModalsProps) {
  switch (modalName) {
    case 'useCard':
      return (
        <UseCardModal
          closeModal={closeModal}
          hasBackButton={hasBackButton}
          modalTitle="Payment method"
          openEditPaymentModal={openEditPaymentModal}
        />
      );
    case 'useAccount':
      return (
        <UseAccountModal closeModal={closeModal} modalTitle="Payment method" />
      );
    default:
      return null;
  }
}
