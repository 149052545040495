import styles from './OrderSummaryCard.module.scss';

type CartItemHeaderProps = {
  logoUrl?: string;
  cartItemCurrentCost: string;
  cartItemOriginalCost?: string;
  cartItemName: string;
  cartItemSubtype: 'Player' | 'Staff' | 'Team';
  cartItemType: 'Tournament';
};

/* OrderSummaryCardHeader*/
export default function OrderSummaryCardHeader({
  logoUrl,
  cartItemCurrentCost,
  cartItemOriginalCost,
  cartItemSubtype,
  cartItemType,
  cartItemName,
}: Readonly<CartItemHeaderProps>) {
  return (
    <div className={styles.orderSummaryCardHeader}>
      {logoUrl ? (
        <img
          className={styles.orderSummaryCardLogo}
          src={logoUrl}
          alt={`${cartItemName} logo`}
        />
      ) : null}
      <div className={styles.textColumn}>
        <div className={styles.topRow}>
          <div className={styles.itemType}>{cartItemType}</div>
        </div>
        <div className={styles.bottomRow}>
          <div className={styles.bottomRowLeft}>
            {cartItemName} &bull; {cartItemSubtype}
          </div>
          <div className={styles.bottomRowRight}>
            {cartItemOriginalCost ? (
              <span className={styles.originalPrice}>
                ${cartItemOriginalCost}
              </span>
            ) : null}
            <span className={styles.currentPrice}>${cartItemCurrentCost}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
/* */
