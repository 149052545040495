import { convertToTitleCase } from 'lib/utils/stringUtils';
import { CartItemData } from '../Checkout.types';
import OrderSummaryCardBody from './OrderSummaryCardBody';
import OrderSummaryCardHeader from './OrderSummaryCardHeader';
import * as S from './OrderSummaryCard.styles';

export type CartSummaryCardProps = {
  cartItemData: CartItemData;
  isShowingAdditionalButtons?: boolean;
};

type CartItemHeaderProps = {
  logoUrl?: string;
  cartItemCurrentCost: string;
  cartItemOriginalCost?: string;
  cartItemName: string;
  cartItemSubtype: 'Player' | 'Staff' | 'Team';
  cartItemType: 'Tournament';
};

export function parseItemType(itemType: string) {
  const splitItemType = itemType.split('_');
  return {
    cartItemType: convertToTitleCase(splitItemType[0]),
    cartItemSubtype: convertToTitleCase(splitItemType[1]),
  };
}

/* OrderSummaryCard */
export default function OrderSummaryCard({
  cartItemData,
  isShowingAdditionalButtons,
}: Readonly<CartSummaryCardProps>) {
  const {
    cartItemType: cartItemSuperType,
    cartItemUuid,
    editUrl,
    options,
    subtotal,
  } = cartItemData;
  const { program, subprograms } = options;
  const { startDate, endDate, locationName, programName, programLogo } =
    program;
  const { details } = subprograms;
  const { cartItemType, cartItemSubtype } = parseItemType(
    cartItemSuperType
  ) as Pick<CartItemHeaderProps, 'cartItemSubtype' | 'cartItemType'>;
  return (
    <S.OrderSummaryCard>
      <OrderSummaryCardHeader
        logoUrl={programLogo}
        cartItemCurrentCost={subtotal}
        cartItemName={programName}
        cartItemOriginalCost="00.00"
        cartItemSubtype={cartItemSubtype}
        cartItemType={cartItemType}
      />
      <OrderSummaryCardBody
        isShowingAdditionalButtons={isShowingAdditionalButtons}
        editUrl={editUrl}
        startDate={startDate}
        cartItemId={cartItemUuid}
        subprograms={details}
        endDate={endDate}
        location={locationName}
      />
    </S.OrderSummaryCard>
  );
}
/* */
