/*PaymentMethodBadgeGroup */
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { PaymentMethodInfo, StoredPaymentMethod } from '../Checkout.types';
import PaymentMethodBadge from './PaymentMethodBadge';
import styles from './PaymentMethodBadge.module.scss';

export function PaymentMethodBadgeGroup({
  groupId,
  paymentMethodData,
  paymentTypes = 'stored',
}: Readonly<{
  groupId?: string;
  paymentMethodData: PaymentMethodInfo | undefined;
  paymentTypes: 'stored' | 'additional';
}>) {
  const { selectedPaymentMethod } = useCheckoutInfo();
  if (!paymentMethodData) {
    return null;
  }
  const allStoredPaymentMethods: StoredPaymentMethod[] = [
    ...(paymentMethodData.storedBankAccounts ?? []),
    ...(paymentMethodData.storedCreditCards ?? []),
  ];
  const groupLabel =
    paymentTypes === 'stored'
      ? 'Stored Payment Methods'
      : 'Additional Payment Method';

  return (
    <div className={styles.paymentMethodBadgeGroup} id={groupId}>
      <p className={styles.badgeGroupLabel}>{groupLabel}</p>
      {allStoredPaymentMethods.map(
        (paymentMethod: StoredPaymentMethod, index) => (
          <PaymentMethodBadge
            isSelectedPaymentMethod={
              paymentMethod.paymentMethodId ===
              selectedPaymentMethod?.paymentMethodId
            }
            paymentMethod={paymentMethod}
            paymentMethodType={paymentTypes}
            key={`${index}_${paymentMethod.last4Digits}`}
          />
        )
      )}
    </div>
  );
}
/* */
