import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Button, Tag } from '@la/ds-ui-components';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import { useDeleteCartItemMutation } from 'redux/services/checkoutApi';
import { CartItemOptionsSubprogramsDetail } from '../Checkout.types';
import RemoveFromCartModal from '../RemoveFromCartModal/RemoveFromCartModal';
import styles from './OrderSummaryCard.module.scss';
import OrderSummaryCardDetails from './OrderSummaryCardDetails';

export type CartItemBodyProps = {
  cartItemId: string;
  editUrl: string;
  isShowingAdditionalButtons?: boolean;
  subprograms: CartItemOptionsSubprogramsDetail[];
  endDate?: string;
  location?: string;
  startDate?: string;
};

type ActionButtonsProps = {
  editUrl: string;
  isShowingAdditionalButtons?: boolean;
  showDetails: boolean;
  showDetailsHandler: () => void;
  openRemoveItemModal: () => void;
};

/* OrderSummaryCardBody*/
export default function OrderSummaryCardBody({
  cartItemId,
  subprograms,
  endDate,
  editUrl,
  isShowingAdditionalButtons,
  startDate,
  location,
}: Readonly<CartItemBodyProps>) {
  const [
    deleteCartItem,
    { isError: hasDeleteCartItemError, isLoading: isDeletingCartItem },
  ] = useDeleteCartItemMutation();
  const [showDetails, setShowDetails] = useState(false);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const { subdomain } = getLAHostnameParts();

  function showDetailsHandler() {
    setShowDetails(!showDetails);
  }

  function openRemoveItemModal() {
    setShowRemoveItemModal(true);
  }

  function closeRemoveItemModal() {
    setShowRemoveItemModal(false);
  }

  function removeCard() {
    deleteCartItem({ cartItemId, siteDomain: subdomain })
      .unwrap()
      .then(() => {
        setShowRemoveItemModal(false);
      })
      .catch(() => {
        setShowRemoveItemModal(false);
      });
  }

  return (
    <div
      className={styles.orderSummaryCardBody}
      data-testid="order-summary-card-body"
    >
      {hasDeleteCartItemError && (
        <ErrorCard message="There was an error removing this item. Please try removing again in a few seconds." />
      )}
      <DivisionList subprograms={subprograms} />
      <PaymentOptions />
      <ActionButtons
        editUrl={editUrl}
        isShowingAdditionalButtons={isShowingAdditionalButtons}
        showDetails={showDetails}
        showDetailsHandler={showDetailsHandler}
        openRemoveItemModal={openRemoveItemModal}
      />
      {showDetails ? (
        <OrderSummaryCardDetails
          endDate={endDate}
          location={location}
          startDate={startDate}
          subprograms={subprograms}
        />
      ) : null}
      {showRemoveItemModal ? (
        <RemoveFromCartModal
          open={showRemoveItemModal}
          closeModal={closeRemoveItemModal}
          removeCard={removeCard}
          loading={isDeletingCartItem}
        />
      ) : null}
    </div>
  );
}
/* */

/* DivisionList*/
function DivisionList({
  subprograms,
}: Readonly<{
  subprograms: CartItemOptionsSubprogramsDetail[];
}>) {
  return (
    <div className={styles.divisionList}>
      {subprograms.map(({ subprogramName, registrations, subprogramId }) => (
        <Tag
          ariaLabel={subprogramName}
          contentText={subprogramName}
          numberBadgeAmount={registrations.length}
          key={subprogramId}
        />
      ))}
    </div>
  );
}
/* */

/* ActionButtons */
function ActionButtons({
  editUrl,
  isShowingAdditionalButtons,
  showDetails,
  showDetailsHandler,
  openRemoveItemModal,
}: Readonly<ActionButtonsProps>) {
  const navigate = useNavigate();
  return (
    <div className={styles.actionButtons}>
      <Button
        rightIcon={
          showDetails ? (
            <CaretUp size={20} weight="bold" />
          ) : (
            <CaretDown size={20} weight="bold" />
          )
        }
        size="medium"
        variant="outline"
        onClick={showDetailsHandler}
      >
        {showDetails ? 'Hide details' : 'Show details'}
      </Button>
      {isShowingAdditionalButtons ? (
        <>
          <Button
            hasPadding={false}
            onClick={() => navigate(editUrl)}
            size="small"
            variant="text"
          >
            Edit
          </Button>
          <Button
            hasPadding={false}
            onClick={openRemoveItemModal}
            size="small"
            variant="text"
          >
            Remove
          </Button>
        </>
      ) : null}
    </div>
  );
}
/* */

/* Payment Options */
function PaymentOptions() {
  return (
    <div className={styles.paymentOptions}>
      <PaymentPlanName />
      <AppliedDiscountCodes />
    </div>
  );
}

function PaymentPlanName() {
  return <div className={styles.paymentPlanName}>Payment plan name TK</div>;
}

function AppliedDiscountCodes() {
  return <div className={styles.appliedDiscountCodes}>Discount codes TK</div>;
}
