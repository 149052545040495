import { CalendarBlank, MapPin } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import { formatDateRange } from 'lib/utils/dateUtils';
import styles from './OrderSummaryCard.module.scss';
import { CartItemBodyProps } from './OrderSummaryCardBody';
import ProgramRegistrationsTable from './ProgramRegistrationsTable';

export default function OrderSummaryCardDetails({
  endDate,
  location,
  startDate,
  subprograms,
}: Readonly<
  Pick<CartItemBodyProps, 'endDate' | 'location' | 'startDate' | 'subprograms'>
>) {
  return (
    <div
      className={styles.orderSummaryCardDetails}
      data-testid="order-summary-card-details"
    >
      <div className={styles.locationAndDate}>
        <LocationInfo location={location} />
        <DateInfo endDate={endDate} startDate={startDate} />
      </div>
      <ProgramRegistrationsTable subprograms={subprograms} />
    </div>
  );
}

function LocationInfo({ location }: { location?: string }) {
  return location ? (
    <span>
      <MapPin size={16} weight="fill" />
      {location}
    </span>
  ) : null;
}

function DateInfo({
  endDate,
  startDate,
}: Readonly<{
  endDate?: string;
  startDate?: string;
}>) {
  return (
    <span>
      <CalendarBlank size={16} weight="fill" />
      {formatDateForCard(startDate, endDate)}
    </span>
  );
}

export function formatDateForCard(startDate?: string, endDate?: string) {
  if (startDate && endDate) {
    return formatDateRange(
      DateTime.fromISO(startDate, { zone: 'utc' }),
      DateTime.fromISO(endDate, { zone: 'utc' })
    );
  } else if (startDate) {
    return formatDateRange(DateTime.fromISO(startDate, { zone: 'utc' }));
  } else {
    return null;
  }
}
