import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import { Column, Row, RowContainer, Typography } from '@la/ds-ui-components';
import { formatDateRange } from '@la/utilities';
import { CardBody, CardHeader, CardTitle } from 'components/Card/Card';
import useMediaQuery from 'lib/hooks/useMediaQuery';
import { breakpointQueries } from 'lib/media-queries/breakpoints';
import { MainContent } from 'domains/Shell/MainContent/MainContent';
import { useTournament } from '../Registration/hooks/useTournament';
import { TOURNAMENT_NOT_FOUND_ERROR_MESSAGE } from '../utils/errorMessages';
import { AboutSection } from './AboutSection/AboutSection';
import { DetailsCard } from './DetailsCard/DetailsCard';
import { Fee, FeesCard } from './FeesCard/FeesCard';
import { LocationCard } from './LocationCard/LocationCard';
import { RegistrationWindowCard } from './RegistrationWindowCard/RegistrationWindowCard';
import styles from './TournamentDetailsPage.module.scss';
import { AboutCard } from './components/AboutCard/AboutCard';
import { DetailsCard as DetailsCardOld } from './components/DetailsCard/DetailsCard';
import { LocationCard as LocationCardOld } from './components/LocationCard/LocationCard';
import { PageTitleSection } from './components/PageTitleSection/PageTitleSection';
import { RegisterSection } from './components/RegisterSection/RegisterSection';
import { RegistrationWindowCard as RegistrationWindowCardOld } from './components/RegistrationWindowCard/RegistrationWindowCard';
import { SecondaryNavigationBar } from './components/SecondaryNavigationBar/SecondaryNavigationBar';
import { TournamentDatesCard } from './components/TournamentDatesCard/TournamentDatesCard';
import { getRegistrationPeriodStatus } from './utils/registration';
import * as S from './TournamentDetailsPage.styles';
import { RegistrationWindowCard as RegistrationWindowCardStyledComponent } from './components/RegistrationWindowCard/RegistrationWindowCard.styles';

export type RegistrationPeriodStatus =
  | {
      state: 'before-registration-period-opens';
      numberOfDays: number;
    }
  | {
      state: 'before-registration-period-ends';
      numberOfDays?: number;
    }
  | {
      state: 'after-registration-period-opens';
    }
  | {
      state: 'after-registration-period-ends';
    }
  | {
      // Used when there are divisions but there is no start or end
      // date for registration (open registration)
      state: 'no-registration-dates';
    }
  | {
      state: 'no-divisions';
    };

export function TournamentDetailsPage() {
  const { playersAndStaffFees } = useFlags();
  const { tournamentId } = useParams();

  const isTabletLandscapeUp = useMediaQuery(
    breakpointQueries.tabletLandscapeUp
  );

  const navigate = useNavigate();

  if (!tournamentId) {
    throw new Error('Tournament ID is not defined');
  }

  const { tournament, isLoading } = useTournament({ id: tournamentId });
  const [showNgAlert, setShowNgAlert] = useState(true);

  const onCloseNgAlert = () => {
    setShowNgAlert(false);
  };

  if (isLoading) {
    return <MainContent />;
  }

  if (!tournament) {
    throw new Error(TOURNAMENT_NOT_FOUND_ERROR_MESSAGE);
  }

  const {
    name,
    aboutHTML,
    experienceLevel,
    gender,
    startDate,
    endDate,
    teamFeeEnabled,
    cost,
    costLabel,
    teamPlayerFeeEnabled,
    teamPlayerFeeAmount,
    freeAgentPlayerFeeEnabled,
    freeAgentPlayerFeeAmount,
    teamStaffMemberFeeEnabled,
    teamStaffMemberFeeAmount,
    freeAgentStaffMemberFeeEnabled,
    freeAgentStaffMemberFeeAmount,
    logoURL,
    registrationStartDate,
    registrationEndDate,
    season,
    sport,
    location,
    hideLocationDetails,
    hotelLinks,
    timeZone,
    divisions,
  } = tournament;

  const divisionNames = divisions.map((division) => division.name);
  const registrationLink = `/app/tournaments/${tournamentId}/registration`;
  const locationLink = location ? `/location/${location.id}` : undefined;
  const fees: Fee[] = [];
  if (teamFeeEnabled && cost) {
    fees.push({ label: 'Team', ...cost });
  }
  if (teamPlayerFeeEnabled && teamPlayerFeeAmount) {
    fees.push({ label: 'Team Player', ...teamPlayerFeeAmount });
  }
  if (freeAgentPlayerFeeEnabled && freeAgentPlayerFeeAmount) {
    fees.push({ label: 'Free Agent Player', ...freeAgentPlayerFeeAmount });
  }
  if (teamStaffMemberFeeEnabled && teamStaffMemberFeeAmount) {
    fees.push({ label: 'Team Staff Member', ...teamStaffMemberFeeAmount });
  }
  if (freeAgentStaffMemberFeeEnabled && freeAgentStaffMemberFeeAmount) {
    fees.push({
      label: 'Free Agent Staff Member',
      ...freeAgentStaffMemberFeeAmount,
    });
  }

  const navigateToRegistration = () => {
    navigate(registrationLink);
  };

  const navigateToLocation = () => {
    if (locationLink) {
      navigate(locationLink);
    }
  };

  const noDivisionsRegistrationPeriodState: RegistrationPeriodStatus = {
    state: 'no-divisions',
  };
  const registrationPeriodStatus = divisions.length
    ? getRegistrationPeriodStatus(
        registrationStartDate,
        registrationEndDate,
        timeZone
      )
    : noDivisionsRegistrationPeriodState;

  const isRegisterLinkShowing = [
    'before-registration-period-ends',
    'no-registration-dates',
  ].includes(registrationPeriodStatus.state);

  const registerSection = (
    <RegisterSection
      navigateToRegistration={navigateToRegistration}
      registrationLink={registrationLink}
      registrationPeriodStatus={registrationPeriodStatus}
    />
  );

  const registrationWindowCard =
    registrationPeriodStatus.state === 'no-divisions' ? (
      <RegistrationWindowCardStyledComponent data-testid="registration-window-card">
        <CardHeader>
          <CardTitle>Registration window</CardTitle>
        </CardHeader>
        <CardBody>
          <Typography variant="ui" size="large">
            N/A
          </Typography>
        </CardBody>
      </RegistrationWindowCardStyledComponent>
    ) : (
      <RegistrationWindowCardOld
        navigateToRegistration={navigateToRegistration}
        registrationLink={registrationLink}
        startDate={registrationStartDate}
        endDate={registrationEndDate}
        isRegisterLinkShowing={isRegisterLinkShowing}
      />
    );

  return (
    <>
      <MainContent>
        {/* TODO: Use data from API endpoint */}
        <PageTitleSection
          logoUrl={logoURL}
          tournamentName={name}
          sport={sport}
        />
        <SecondaryNavigationBar
          hasVisibleTeams={divisions.some((division) => division.shouldDisplay)}
          registerSection={isTabletLandscapeUp ? registerSection : null}
          tournamentId={tournamentId}
        />
        {playersAndStaffFees ? (
          <div className={styles.tournamentDetailsPage}>
            <div className={styles.tournamentDetails}>
              <RowContainer>
                <Row>
                  <Column width={[4, 5, 8]}>
                    <Row>
                      <Column width={12}>
                        <div className={styles.header}>
                          <Typography size="large" variant="headline">
                            About {name}
                          </Typography>
                          <Typography size="xl" variant="ui" weight="bold">
                            {formatDateRange(
                              DateTime.fromISO(startDate),
                              endDate ? DateTime.fromISO(endDate) : undefined
                            )}
                          </Typography>
                        </div>
                      </Column>
                    </Row>
                  </Column>
                  <Column width={[4, 3, 4]}>
                    <></>
                  </Column>
                </Row>
                <Row>
                  <Column width={[4, 5, 8]}>
                    <Row>
                      <Column width={[4, 3, 3]}>
                        <RegistrationWindowCard
                          endDate={registrationEndDate}
                          hasRegistrationWindow={
                            registrationPeriodStatus.state !== 'no-divisions'
                          }
                          href={registrationLink}
                          showRegisterButton={isRegisterLinkShowing}
                          startDate={registrationStartDate}
                        />
                      </Column>
                      <Column width={[4, 5, 9]}>
                        <LocationCard
                          href={locationLink}
                          name={location?.name}
                        />
                      </Column>
                      <Column width={12}>
                        <DetailsCard
                          ageGroups={[6, 8, 10, 11]}
                          gender={gender}
                          hotelLink={hotelLinks}
                          level={experienceLevel}
                          season={season}
                          sport={sport}
                        />
                      </Column>
                      {aboutHTML ? (
                        <Column width={12}>
                          <AboutSection description={aboutHTML} />
                        </Column>
                      ) : null}
                    </Row>
                  </Column>
                  <Column width={[4, 3, 4]}>
                    <FeesCard fees={fees} />
                  </Column>
                </Row>
              </RowContainer>
            </div>
          </div>
        ) : (
          <S.CardSection>
            {showNgAlert ? (
              <S.Alert>
                <S.ShootingStarIconContainer />
                <Typography variant="ui" size="large">
                  Welcome to our new tournament registration experience!
                </Typography>
                <S.CloseIconClickableContainer onClick={onCloseNgAlert}>
                  <S.CloseIconContainer />
                </S.CloseIconClickableContainer>
              </S.Alert>
            ) : null}
            {registrationWindowCard}
            <TournamentDatesCard
              isShowingLocation={!hideLocationDetails}
              startDate={startDate}
              endDate={endDate}
            />
            {!hideLocationDetails ? (
              <LocationCardOld
                navigateToLocation={navigateToLocation}
                locationLink={locationLink}
                name={location?.name}
              />
            ) : null}
            <AboutCard content={aboutHTML} />
            <DetailsCardOld
              divisionNames={divisionNames}
              gender={gender}
              cost={cost}
              costLabel={costLabel}
              hotelLink={hotelLinks}
              season={season}
              sport={sport}
            />
          </S.CardSection>
        )}
      </MainContent>
      {!isTabletLandscapeUp ? (
        <S.RegisterSectionContainer>
          {registerSection}
        </S.RegisterSectionContainer>
      ) : null}
    </>
  );
}
