import { NotePencil, Trash } from '@phosphor-icons/react';
import clsx from 'clsx';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { useCheckoutInfo } from 'lib/context/CheckoutInfoContext';
import { capitalizeFirstLetter } from 'lib/utils/stringUtils';
import {
  PaymentMethod,
  StoredBankAccount,
  StoredPaymentMethod,
} from '../Checkout.types';
import styles from './PaymentMethodBadge.module.scss';
import {
  paymentMethodBadgeConfig,
  PaymentMethodDetailConfig,
} from './PaymentMethodBadgeConfig';

export type PaymentMethodBadgeProps = {
  isSelectedPaymentMethod: boolean;
  paymentMethod: StoredPaymentMethod;
  paymentMethodType: 'additional' | 'stored';
};

export function isBankAccount(
  storedType: PaymentMethod
): storedType is StoredBankAccount {
  return (storedType as StoredBankAccount).paymentType === 'ACH';
}

/* PaymentMethodBadge */
export default function PaymentMethodBadge({
  isSelectedPaymentMethod,
  paymentMethod,
  paymentMethodType,
}: PaymentMethodBadgeProps) {
  const { updateSelectedPaymentMethod } = useCheckoutInfo();
  let paymentMethodDetailConfig: PaymentMethodDetailConfig;

  const isPaymentMethodBank = isBankAccount(paymentMethod);
  if (isPaymentMethodBank) {
    paymentMethodDetailConfig = paymentMethodBadgeConfig.bank;
  } else {
    const network = paymentMethod.paymentNetwork;
    const creditCard = paymentMethodBadgeConfig.creditCard;
    paymentMethodDetailConfig = creditCard[network];
  }

  const setSelectedPaymentMethod = () => {
    updateSelectedPaymentMethod(paymentMethod);
  };

  const badgeClassName = clsx(
    styles.paymentMethodBadge,
    paymentMethodType === 'additional' && styles.additionalPaymentMethod
  );

  return (
    <div
      className={badgeClassName}
      data-testid="payment_badge"
      onClick={setSelectedPaymentMethod}
    >
      <RadioButton
        checked={isSelectedPaymentMethod}
        handleChange={setSelectedPaymentMethod}
        iconColor={'var(--blue-grey-600)'}
      />
      {paymentMethodDetailConfig ? (
        <img
          src={paymentMethodDetailConfig.logo}
          alt={paymentMethodDetailConfig.name}
        />
      ) : null}
      <div className={styles.accountInfo}>
        {isPaymentMethodBank
          ? capitalizeFirstLetter(paymentMethod.accountType)
          : 'Card'}{' '}
        ending in {paymentMethod.last4Digits}
      </div>
      {paymentMethodType === 'additional' ? (
        <>
          <NotePencil
            size={20}
            weight="bold"
            fill={'var(--secondary-600)'}
            onClick={() => {
              console.log('edit');
            }}
          />
          <Trash
            size={20}
            weight="bold"
            fill={'var(--secondary-600)'}
            onClick={() => {
              console.log('delete');
            }}
          />
        </>
      ) : null}
    </div>
  );
}
/* */
